import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import {EntrarComponent} from "./pages/login/entrar/entrar.component";
import {LoginComponent} from "./pages/login/login.component";
import {ValidaInscricaoComponent} from "./pages/valida-inscricao/valida-inscricao.component";

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'login',
    component: LoginComponent,
    children: [
      {
        path: '',
        component: EntrarComponent
      }
    ]
  },
  {
    path: 'valida-inscricao/:idUsuario/:idCurso/:token',
    component: ValidaInscricaoComponent
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
