import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  constructor() { }

  getItem(item) {
    const a = localStorage.getItem(item);

    if (a === undefined || a === null) {
      return '';
    }

    return a;
  }

  setItem(item, value) {
    localStorage.setItem(item, value);
  }

}
