import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../service/auth.service";
import {MensagemService} from "../../../util/mensagem.service";
import {Router} from "@angular/router";

@Component({
  selector: 'ngx-entrar',
  templateUrl: './entrar.component.html',
  styleUrls: ['./entrar.component.scss']
})
export class EntrarComponent implements OnInit {
  login = ''
  senha = ''

  constructor(private authService: AuthService, private mensagemService: MensagemService,
              private router: Router) { }

  ngOnInit(): void {
  }

  entrar(){
    this.authService.login({login: this.login, senha: this.senha}).subscribe(
      (data) => {
        this.authService.token = data['token'];
        this.authService.usuarioLogado = data['user'];
        this.authService.logado = true;

        this.mensagemService.showSuccess('Entrou', data['msg']);

        localStorage.setItem('token', data['token']);
        localStorage.setItem('usuarioLogado', JSON.stringify(data['user']));
        localStorage.setItem('logged', 'true');

        this.router.navigate(['/pages']);
      },
      (error) => {
        this.mensagemService.showDanger('Erro', error.error['msg']);
      }
    )
  }
}
