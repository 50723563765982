<div class="header-container bg-blue-dark">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" [routerLink]="['']" class="text-info">
      <img class="img-fluid w-75" src="assets/images/logo.png">
    </a>
  </div>
<!--  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">-->
<!--    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>-->
<!--  </nb-select>-->
</div>

<div class="header-container bg-blue-dark ">
  <nb-actions size="small">
<!--    <nb-action class="control-item" icon="email-outline"></nb-action>-->
    <nb-action class="control-item text-light">Rua 1129, nº 200, Setor Marista Goiânia – Goiás CEP: 74175-140</nb-action>
    <nb-action class="control-item text-light"><nb-icon icon="phone-outline"></nb-icon> (62) 3212-6060 (62) 3213-2522</nb-action>
    <nb-action class="control-item text-light" *ngIf="authService.logado" icon="bell-outline"></nb-action>
    <nb-action class="user-action text-light bg-blue-light" *ngIf="authService.logado">
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="authService.usuarioLogado.login"
               class="text-light"
               [color]="textColor"
               nbContextMenuTag="menuHeader">
      </nb-user>
    </nb-action>
    <nb-action class="user-action text-light" *ngIf="!authService.logado">
        <button class="btn btn-outline-primary" [routerLink]="['/login']">Entrar</button>
    </nb-action>

  </nb-actions>
</div>
