import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class InscricaoService {

  constructor(private http: HttpClient, private storage: StorageService) { }

  solicitarInscricao(idCurso) {
    return this.http.get<any>(`${environment.backend}/inscricao/solicitar/${idCurso}`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization': this.storage.getItem('token')})
    });
  }

  validarInscricao(idUsuario, idCurso, token){
    return this.http.get<any>(`${environment.backend}/inscricao/valide-inscreva/${idUsuario}/${idCurso}/${token}`,
      {
      headers: new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization': this.storage.getItem('token')})
    });
  }
}
