import { Component, OnInit } from '@angular/core';
import {InscricaoService} from "../../service/inscricao.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'ngx-valida-inscricao',
  templateUrl: './valida-inscricao.component.html',
  styleUrls: ['./valida-inscricao.component.scss']
})
export class ValidaInscricaoComponent implements OnInit {
  msg = 'Aguarde estamos validando a inscrição'
  load = true

  constructor(private inscricaoService: InscricaoService, private aroute: ActivatedRoute) { }

  ngOnInit(): void {
    const token = this.aroute.snapshot.paramMap.get('token');
    const idCurso = this.aroute.snapshot.paramMap.get('idCurso');
    const idUsuario = this.aroute.snapshot.paramMap.get('idUsuario');

    this.inscricaoService.validarInscricao(idUsuario, idCurso, token).subscribe(
      (data) => {
        this.msg = data['msg'];
        this.load = false
      },(error) =>{
        this.msg = error.error['msg'];
        this.load = false;
      }
    )
  }

}
