<nb-layout windowMode>
  <nb-layout-header fixed>
    <ngx-header></ngx-header>
  </nb-layout-header>

  <nb-layout-column>
    <div class="content">
      <div class="row">
        <div class="col">
          <nb-card accent="danger" size="tiny" [nbSpinner]="load"
                   nbSpinnerStatus="danger"
                   nbSpinnerSize="large"
                   nbSpinnerMessage="">
            <nb-card-header>Inscrevendo aluno</nb-card-header>
            <nb-card-body>
              <p>
                {{msg}}
              </p>
            </nb-card-body>
          </nb-card>
        </div>
      </div>
    </div>
  </nb-layout-column>

  <nb-layout-footer fixed>
    <ngx-footer></ngx-footer>
  </nb-layout-footer>
</nb-layout>
