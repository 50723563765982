import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Usuario} from "../model/usuario";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  usuarioLogado: Usuario
  logado = false
  token = ''

  constructor(private http: HttpClient) { }

  login(form: any){
    // console.log(`${environment.backend}/login`);
    return this.http.post<any>(`${environment.backend}/login`, form, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  getUsuarioLogado(){
    this.logado = localStorage.getItem('logged') === 'true';

    if(this.logado){
      this.usuarioLogado = JSON.parse(localStorage.getItem('usuarioLogado'));
    }

    return this.usuarioLogado;
  }

  deslogar(){
    this.logado = false;
    localStorage.setItem('logged', 'false');
    localStorage.setItem('usuarioLogado', '');
    localStorage.setItem('token', '');
  }
}
