<div class="container justify-content-center">
  <nb-card>
    <nb-card-body>
      <div class="row justify-content-center" (submit)="entrar()">
        <form>
          <div class="form-group">
            <label for="loginTxt">Login</label>
            <input type="text" [(ngModel)]="login" name="login" class="form-control" id="loginTxt" aria-describedby="helpLogin">
            <small id="helpLogin" class="form-text text-muted">Login cadastrado por algum médico ou administrador</small>
          </div>
          <div class="form-group">
            <label for="inputSenha">Senha</label>
            <input type="password" [ngClass]="{ 'is-active': true}" [(ngModel)]="senha" name="senha" class="form-control" id="inputSenha">
          </div>
          <button type="submit" class="btn btn-primary">Entrar</button>
        </form>
      </div>
    </nb-card-body>
  </nb-card>
</div>
